import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const DashboardBox = document.getElementById('settings-box');

    if (DashboardBox) {

        //check_id_token();
        // get notifications

        $("#pp_submit_threshold").click(function(event) {
            event.preventDefault();
            //console.log("clicked submit");
            set_threshold();
        });

        $("#pp_submit_tariff").click(function(event) {
            event.preventDefault();
            //console.log("clicked submit");
            set_tariff();
        });

        $("#dashboard-logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        $("#logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        function stringToHslColor(str, s, l) {
            //a string (str): we will use it to get the hue parameter of the returned color;
            //the saturation (s) of returned color: a number between 0 and 100;
            //the lightness (l) of returned color: a number between 0 and 100.
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
        }

        function get_initials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

                        
            let initials = [...name.matchAll(rgx)] || [];


            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        }


        function set_tariff() {
            var rate, power_provider, date;
            console.log("clicked set tariff");

            power_provider = $("#pp_powerprovider").val();
            rate = $("#pp_rate").val();
            date = $("#pp_date").val();

            if (power_provider == "" ||
                rate == "" ||
                date == "") {
                alert('Please fill up the power provider, the rate, and the effective date');
                return;
            }

            $.ajax({
                url: "https://api2.thesmarthome.sg/tariff/provider",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "Authorization": localStorage.getItem("3datavis-id-token")
                },
                success: function(data) {
                    //console.log(data);
                    alert("Successfully set the new tariff values");
                    //console.log(data["data"][0][2]);
                    //$("#tariff").val(data["data"][0][2]);
                    window.location.reload();
                },
                data: JSON.stringify({
                    pp_rate: rate,
                    pp_name: power_provider,
                    pp_date: date,
                    smartdbnumber: localStorage.getItem("smartdbnumber"),
                    username: localStorage.getItem("3datavis-username")
                }),
            });
        }

        function set_threshold() {
            var threshold;
            console.log("clicked set threshold");

            threshold = $("#pp_threshold").val();

            if (threshold == "") {
                alert('Please enter a threshold value');
                return;
            }

            $.ajax({
                url: "https://api2.thesmarthome.sg/tariff/threshold",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "Authorization": localStorage.getItem("3datavis-id-token")
                },
                success: function(data) {
                    //console.log(data);
                    alert("Successfully set the new threshold value");
                    //console.log(data["data"][0][2]);
                    //$("#tariff").val(data["data"][0][2]);
                    window.location.reload();
                },
                data: JSON.stringify({
                    threshold: threshold,
                    smartdbnumber: localStorage.getItem("smartdbnumber"),
                    username: localStorage.getItem("3datavis-username")
                }),
            });
        }

        function get_tariff() {

            var myToken = localStorage.getItem("3datavis-id-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/tariff/user",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "Authorization": localStorage.getItem("3datavis-id-token")
                },
                success: function(data) {
                    console.log(data);

                    if (data["data"]["pp_rate"]) {
                        $("#pp_tariff_info").text(data["data"]["pp_name"] + ": $ " + data["data"]["pp_rate"] + " per kWh (eff. date:" + data["data"]["pp_date"] + ")");
                        $("#pp_threshold_info").text(data["data"]["pc_threshold"]);
                    }

                    

                    
                },
                data: JSON.stringify({
                    smartdbnumber: localStorage.getItem("smartdbnumber"),
                    username: localStorage.getItem("3datavis-username")
                }),
            });

        }

        

        function check_id_token() {

            var myToken = localStorage.getItem("3datavis-id-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/id-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    if (data["success"] == true) {
                        localStorage.setItem("3datavis-username", data["username"]);
                        //console.log(data);
                        let name = data["name"];
                        let initials = "";

                        initials = get_initials(name);

                        $(".avatar-circle-xs").css('background-color', stringToHslColor(data["username"], 50, 60));
                        $('#set-initials').attr('data-letters', initials);
                        $("#set-initials").text(data["name"]);
                        //$("#set-fullname").text(data["firstname"] + " " + data["lastname"]);
                        $("#set-username").text(data["username"]);

                        get_tariff();

                        $("#loader").addClass("fadeOut");
                    } else {
                        check_refresh_token();
                    }
                },
                data: JSON.stringify({
                    token: myToken
                }),
            });
        }


        function check_refresh_token() {

            var myToken = localStorage.getItem("3datavis-refresh-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/refresh-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    console.log("ID");
                    console.log(data);
                    if (data["success"] == true) {
                        localStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        check_id_token();
                    } else {
                        alert('Your session has expired! Please login again and refresh this page.');
                        window.location.href = '/signin';
                    }
                },
                data: JSON.stringify({
                    username: localStorage.getItem("3datavis-username"),
                    refresh_token: myToken
                }),
            });
        }

        window.addEventListener('load', () => {
            $("#loader").removeClass("fadeOut");
            check_id_token();
        });

    }
}())