import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const ResetPasswordBox = document.getElementById('forgot-password-box');

    if (ResetPasswordBox) {

        //console.log("Inside Forgot PASSWORD");
        //console.log("Trigger code");

        //request_change_password();
        //var pass, pass1, pass2, username, email, fullname, code;

        $("#forgotBtn").click(function() {
            event.preventDefault();
            set_password();
        });

        // Get the input field
        var input_password1 = document.getElementById("password1");
        input_password1.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                set_password();
            }
        });

        var input_password2 = document.getElementById("password2");
        input_password2.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                set_password();
            }
        });

        var input_username = document.getElementById("username");
        input_username.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                set_password();
            }
        });

        var input_totp = document.getElementById("totp");
        input_totp.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                set_password();
            }
        });


        function set_password() {
            console.log("Clicked set password!");
            
            //var myToken = localStorage.getItem("3datavis-session-token");

            //console.log(myToken);
            
            var pass1, pass2, username, totp;

            pass1 = $("#password1").val();
            pass2 = $("#password2").val();
            username = $("#username").val();
            totp = $("#totp").val();

            if (pass1 == "" || pass2 == "" || username == "" || totp == "") {
                $("#error-message").text("Please fill in the fields");
                $("#error-message").css("color", "red");
                return;
            }

            if (pass1 != pass2) {
                $("#error-message").text("Passwords do not match");
                $("#error-message").css("color", "red");
                return;
            }

            $("#loader").removeClass("fadeOut");
            
            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/confirm-forgot-password",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function (data) {
                    console.log(data);
                    if (data["success"]) {
                        window.location.href = '/signin';
                    } else {
                        $("#loader").addClass("fadeOut");
                        $("#error-message").text(data["message"]);
                        $("#error-message").css("color", "red");
                    }
                },
                data: JSON.stringify({
                    username: username,
                    password: pass1,
                    code: totp
                }),
            });
        }
    }
}())