import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Signin
    // ------------------------------------------------------

    const RegisterControllerBox = document.getElementById('register-controller-box');

    if (RegisterControllerBox) {



        $("#register").click(function() {
            event.preventDefault();

            getGatewayID();

            //verifyPhone();
        });

        var smart_db_number;

        function getGatewayID() {
            // https://provisioning.thesmarthome.sg/api/v1/gateway/uat2test242n3

            if(!document.getElementById('isTACChecked').checked) {
                $("#error-message").text("Please accept terms and conditions");
                $("#error-message").css("color", "red");
                return;
            }

            smart_db_number = $("#smart_db_number").val();
            $("#error-message").text("");

            
            if (smart_db_number == "") {
                $("#error-message").text("Smart DB Number is missing");
                $("#error-message").css("color", "red");
                return;
            }

            $("#loader").removeClass("fadeOut");

            $.ajax({
                url: "https://provisioning.thesmarthome.sg/api/v1/gateway/"+smart_db_number.toLocaleLowerCase(),
                type: "get",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    console.log(data);
                    
                    if ("response" in data) {
                        console.log("SUCCESS");
                        localStorage.setItem("gatewayid", data["response"]);
                        localStorage.setItem("smartdbnumber", smart_db_number);
                        verifyPhone();
                    } else {
                        $("#loader").addClass("fadeOut");
                        $("#error-message").text("Invalid Smart DB Number - Please contact your administrator");
                        $("#error-message").css("color", "red");
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $("#loader").addClass("fadeOut"); 
                    $("#error-message").text("GatewayID Verification failed - Please contact your administrator");
                    $("#error-message").css("color", "red");
                },
                timeout: 3000 // sets timeout to 3 seconds
            });
        }

        var unit, level, postcode, phone;

        function verifyPhone() {
            $("#loader").removeClass("fadeOut");

            unit = $("#unit_number").val();
            level = $("#level").val();
            postcode = $("#postal_code").val();
            phone = $("#mobile_number").val();
            $("#error-message").text("");

            
            if (unit == "") {
                $("#error-message").text("Unit is missing");
                $("#error-message").css("color", "red");
                return;
            }
            if (level == "") {
                $("#error-message").text("Level is missing");
                $("#error-message").css("color", "red");
                return;
            }
            if (postcode == "") {
                $("#error-message").text("Postal code is missing");
                $("#error-message").css("color", "red");
                return;
            }
            if (phone == "") {
                $("#error-message").text("Phone is missing");
                $("#error-message").css("color", "red");
                return;
            }

            localStorage.setItem("level", level);
            localStorage.setItem("unit", unit);
            localStorage.setItem("postcode", postcode);
            localStorage.setItem("phone", phone);

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/validate-phone",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log(data);
                    $("#loader").addClass("fadeOut");
                    if (data["checkphone"]) {
                        //console.log("SUCCESS");
                        localStorage.setItem("secret-session", data["secret"])
                        location.href = '/signup';
                    } else {
                        $("#error-message").text("Invalid Flat Information - Please contact your administrator");
                        $("#error-message").css("color", "red");
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    $("#loader").addClass("fadeOut"); 
                    $("#error-message").text("Checkphone API failed - Please contact your administrator");
                    $("#error-message").css("color", "red");
                },
                data: JSON.stringify({
                    "PostalCode": postcode,
                    "Level": level,
                    "Unit": unit,
                    "Phone": phone
                }),
                timeout: 3000 // sets timeout to 3 seconds
            });
        }


    }
}())