import * as $ from 'jquery';
import Chart from 'chart.js';
import moment from 'moment';


export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const DashboardBox = document.getElementById('consumption-box');

    var config, config2, config3, config_appl;

    if (DashboardBox) {
         config = {
            type: "line",
            data: {
               labels: [],
               datasets: [{
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               }]
            },
            options: {
               responsive: true,
               legend: {
                  display: false
               },
               tooltips: {
                  mode: "index",
                  intersect: false,
               },
               hover: {
                  mode: "nearest",
                  intersect: true
               },
               scales: {
                  xAxes: [{
                     type: "time",
                     time: {displayFormats: { minute: "MM-DD HH:mm" }},
                     display: true,
                     scaleLabel: {
                        display: true,
                        labelString: "Time"
                     }
                  }],
                  yAxes: [{
                     display: true,
                     scaleLabel: {
                        display: false,
                        labelString: "Value"
                     }
                  }]
               }
            }
         };

         config2 = {
            type: "line",
            data: {
               labels: [],
               datasets: [{
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               }]
            },
            options: {
               responsive: true,
               legend: {
                  display: false
               },
               tooltips: {
                  mode: "index",
                  intersect: false,
               },
               hover: {
                  mode: "nearest",
                  intersect: true
               },
               scales: {
                  xAxes: [{
                     type: "time",
                     time: { displayFormats: { minute: "MM-DD HH:mm" } },
                     display: true,
                     scaleLabel: {
                        display: true,
                        labelString: "Time"
                     }
                  }],
                  yAxes: [{
                     display: true,
                     scaleLabel: {
                        display: true,
                        labelString: "Value"
                     }
                  }]
               }
            }
         };

         config3 = {
            type: "line",
            data: {
               labels: [],
               datasets: [{
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               },
               {
                  label: "Load",
                  lineTension: 0,
                  backgroundColor: "red",
                  borderColor: "red",
                  data: [],
                  fill: false,
                  pointRadius: 0
               }]
            },
            options: {
               responsive: true,
               legend: {
                  display: false
               },
               tooltips: {
                  mode: "index",
                  intersect: false,
               },
               hover: {
                  mode: "nearest",
                  intersect: true
               },
               scales: {
                  xAxes: [{
                     type: "time",
                     time: { displayFormats: { minute: "MM-DD HH:mm" } },
                     display: true,
                     scaleLabel: {
                        display: true,
                        labelString: "Time"
                     }
                  }],
                  yAxes: [{
                     display: true,
                     scaleLabel: {
                        display: false,
                        labelString: "Value"
                     }
                  }]
               }
            }
         };

         config_appl = {
            type: "scatter",
            data: {
               labels: [],
               datasets: []
             },
             options: {
               responsive: true,
               legend: {
                  display: true,
                  labels: {
                     color: 'rgb(255, 99, 132)'
                 }
               },
               tooltips: {
                  mode: "index",
                  intersect: false,
               },
               hover: {
                  mode: "nearest",
                  intersect: true
               },
               scales: {
                  xAxes: [{
                     type: "time",
                     time: { displayFormats: { minute: "MM-DD HH:mm" } },
                     display: true,
                     scaleLabel: {
                        display: true,
                        labelString: "Time"
                     }
                  }],
                  yAxes: [{
                     display: true,
                     scaleLabel: {
                        display: false,
                        labelString: "Value"
                     }
                  }]
               }
            }
         };

        $("#dashboard-logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("3datavis-smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        $("#logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("3datavis-smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        function stringToHslColor(str, s, l) {
            //a string (str): we will use it to get the hue parameter of the returned color;
            //the saturation (s) of returned color: a number between 0 and 100;
            //the lightness (l) of returned color: a number between 0 and 100.
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
        }

        function get_initials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

                        
            let initials = [...name.matchAll(rgx)] || [];


            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        }

        var COLORS = [
         "#e41a1c",
         "#377eb8",
         "#4daf4a",
         "#984ea3",
         "#ff7f00",
         "#ffff33",
         "#a65628",
         "#f781bf"
       ]

        

        function get_devices_id() {

         console.log("GET DEVICES ID");


          //globals.user.userGatewayID

            $.ajax({
               url: "https://provisioning.thesmarthome.sg/api/v1/appliances/"+localStorage.getItem("gatewayid").toLocaleLowerCase(),
               type: "get",
               dataType: "json",
               contentType: "application/json",
               success: function(data) {
                  console.log(data);

                  if ("response" in data) {
                     
                     console.log(data["response"]["result"]);

                     const obj = JSON.parse(data["response"]["result"]);

                     console.log(obj[0]["appliances"]);

                     get_devices_consumption(obj[0]["appliances"]);
                  } else{
                     alert(data["errorDescription"]);
                  }

               }
            });
        }

        function get_devices_consumption(myobject) {

         console.log("GET DEVICES Consumption");
         console.log(myobject);

          //globals.user.userGatewayID

            $.ajax({
               url: "https://api2.thesmarthome.sg/energy/consumption-by-id",
               type: "post",
               dataType: "json",
               contentType: "application/json",
               headers: {
                  "Authorization": localStorage.getItem("3datavis-id-token")
               },
               success: function(data) {
                  console.log(data);

                  const myTariff = localStorage.getItem("tariff");

                  const myLabel = [];
                  const data1 = [];
                  const data2 = [];

                  for (let i = 0; i < data["data"].length; i++) {

                     var tmpName = "N/A";

                     for(let j = 0; j < myobject.length; j++) {
                        //console.log(myobject[j]["id"]);
                        //console.log(data["sum_data"][i][0][0]);
                        if (parseInt(myobject[j]["id"]) == data["data"][i]["id"]) {

                           //console.log(myobject[j]["friendlyName"]);
                           tmpName = myobject[j]["friendlyName"];
                           break;
                        }
                     }

                     myLabel[i] = tmpName;
                     data1[i] = data["data"][i]["sum_energy"];
                     data2[i] = data["data"][i]["sum_energy"] * myTariff;
                   }

                  const barChartBox = document.getElementById('bar-chart');

        

        if (barChartBox) {
          const barCtx = barChartBox.getContext('2d');
      
          myChart = new Chart(barCtx, {
            type: 'bar',
            data: {
              labels: myLabel,
              datasets: [{
                label           : 'Total Energy Consumption',
                backgroundColor : COLORS[0],
                borderColor     : COLORS[0],
                borderWidth     : 1,
                data            : data1,
              }, {
                label           : 'Total Spending',
                backgroundColor : COLORS[1],
                borderColor     : COLORS[1],
                borderWidth     : 1,
                data            : data2,
              }],
            },
      
            options: {
              responsive: true,
              legend: {
                position: 'bottom',
              },
            },
          });
        }

               },
               data: JSON.stringify({
                  smartdbnumber: localStorage.getItem("smartdbnumber"),
                  total_past_months: $("#cars").val()
              }),
            });
        }

      



        $("#cars").change(function () {
         
         $('#bar-chart').remove(); // this is my <canvas> element
         $('#graph-container').append('<canvas id="bar-chart" height="220"><canvas>');

            get_devices_id();
        });

         function get_data_energy() {
            var myToken = localStorage.getItem("3datavis-id-token");
            var mySmartdbnumber = localStorage.getItem("3datavis-smartdbnumber");

            //localStorage.setItem("3datavis-smartdbnumber", "");
            //localStorage.setItem("3datavis-username", "");
            duration = $("#cars").val();
            //console.log(duration);
            $.ajax({
               url: "https://api.thesmarthome.sg/energy/consumption",
               type: "post",
               dataType: "json",
               contentType: "application/json",
               success: function(data) {
                  console.log(data);

                  /*

                  var datasets = [];
                  for (var m=0; m< data["appliance_id"].length; m++) { //m -> x
                     //console.log(m);
                     var dataitem = new Array(data["unique_time"].length).fill(0.0, 0, data["unique_time"].length);
                     datasets.push(dataitem);
                  }

                  var sorted_unique_time = data["unique_time"].sort();

                  for (var m=0; m< data["appliance_id"].length; m++) { //m -> x -> 5
                     for (var i=0; i< data["unique_time"].length; i++) { // i -> y -> 9
                        for (var j=0; j< data["data"].length; j++) { // flatten this
                           for (var k=0; k< data["data"][j].length; k++) {
                           
                              if (data["data"][j][k][0] == (sorted_unique_time[i]/1000)) {
                                 //console.log(m, data["data"][j][k]);
                                 //console.log(data["data"][j][k][0], data["unique_time"][i], i, m, data["data"][j][k]);
                                 datasets[ data["appliance_id"].indexOf(data["data"][j][k][3]) ][i] = data["data"][j][k][2];
                                 break;
                              }
                           }
                        }
                     }
                  }
                  console.log(datasets);
                  console.log(sorted_unique_time);

                  config_appl.data.labels = sorted_unique_time;

                  var data_container = [];
                  for (var i=0; i< data["appliance_id"].length; i++) {
                     data_container.push({
                        label: data["appliance_id"][i],
                        data: datasets[i],
                        borderWidth: 1,
                        fill: true,
                        lineTension: 0,
                        backgroundColor: COLORS[i%8]
                     });
                  }
                  config_appl.data.datasets = data_container;

                    $('#myChart4').remove(); // this is my <canvas> element
                    $('#graph-container4').append('<canvas id="myChart4"><canvas>');
                    var ctx4   = document.getElementById( "myChart4" ).getContext( "2d" ) ;
                    myChart4 = new Chart( ctx4, config_appl ) ;
                    */
                },
                data: JSON.stringify({
                    smartdbnumber: mySmartdbnumber,
                    duration: duration
                }),
            });
        }

      

      function find(needle, haystack) {
         var results = [];
         var idx = haystack.indexOf(needle);
         while (idx != -1) {
             results.push(idx);
             idx = haystack.indexOf(needle, idx + 1);
         }
         return results;
     }
        // color selection
        /*
        #e41a1c
        #377eb8
        #4daf4a
        #984ea3
        #ff7f00
        #ffff33
        #a65628
        #f781bf
        */
        var duration;
        var myChart, myChart2, myChart3, myChart4;

        function get_data() {
            var myToken = localStorage.getItem("3datavis-id-token");
            var mySmartdbnumber = localStorage.getItem("3datavis-smartdbnumber");
            duration = $("#cars").val();
            //console.log(duration);
            $.ajax({
                url: "https://api.thesmarthome.sg/energy",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                  
                    console.log(data["data1"]);

                    /*
                    var labels = [];
                    var mydata = [];
                    var mydata2 = [];
                    var mydata3 = [];
                    var mydata4 = [];
                    var mydata5 = [];

                    // moment.unix(yourUnixEpochTime).format('dddd, MMMM Do, YYYY h:mm:ss A')
                    for(var i=0; i < data["data1"].length; i++) {
                        //console.log(moment.unix(data["data1"][i][0]).format('MMMM Do, YYYY h:mm:ss A'));
                        labels.push(moment.unix(data["data1"][i][0]).format('YYYY-M-D H:m'));
                        mydata.push(data["data1"][i][1]);
                        mydata2.push(data["data1"][i][2]);
                        mydata3.push(data["data1"][i][3]);
                        mydata4.push(data["data1"][i][4]);
                        mydata5.push(data["data1"][i][5]);
                    }

                    config.data.labels = labels;
                    config.data.datasets[0].label = "active_power";
                    config.data.datasets[0].data = mydata;
                    config.data.datasets[1].data = [];
                    config.data.datasets[2].data = [];
                    config.data.datasets[0].backgroundColor = "#377eb8";
                    config.data.datasets[0].borderColor = "#377eb8";
                    // Create chart
                    $('#myChart').remove(); // this is my <canvas> element
                    $('#graph-container').append('<canvas id="myChart"><canvas>');
                    var ctx   = document.getElementById( "myChart" ).getContext( "2d" ) ;
                    myChart = new Chart( ctx, config ) ;

                    config2.data.labels = labels;
                    config2.data.datasets[0].data = mydata2;
                    config2.data.datasets[1].data = [];
                    config2.data.datasets[2].data = [];
                    config2.data.datasets[0].label = "energy";
                    config2.data.datasets[0].backgroundColor = "#377eb8";
                    config2.data.datasets[0].borderColor = "#377eb8";
                    //console.log(config.options.scales.yAxes[0].labelString);
                    $('#myChart2').remove(); // this is my <canvas> element
                    $('#graph-container2').append('<canvas id="myChart2"><canvas>');
                    //createChart(labels, data);
                    var ctx2   = document.getElementById( "myChart2" ).getContext( "2d" ) ;

                    //console.log("Second config");
                    myChart2 = new Chart( ctx2, config2 ) ;

                    config3.data.labels = labels;
                    config3.data.datasets[0].data = mydata3;
                    config3.data.datasets[0].label = "voltage";
                    config3.data.datasets[0].backgroundColor = "#e41a1c";
                    config3.data.datasets[0].borderColor = "#e41a1c";
                    config3.data.datasets[1].data = mydata4;
                    config3.data.datasets[1].label = "current";
                    config3.data.datasets[1].backgroundColor = "#377eb8";
                    config3.data.datasets[1].borderColor = "#377eb8";
                    config3.data.datasets[2].data = mydata5;
                    config3.data.datasets[2].label = "frequency";
                    config3.data.datasets[2].backgroundColor = "#4daf4a";
                    config3.data.datasets[2].borderColor = "#4daf4a";
                    //console.log(config.options.scales.yAxes[0].labelString);
                    $('#myChart3').remove(); // this is my <canvas> element
                    $('#graph-container3').append('<canvas id="myChart3"><canvas>');
                    var ctx3   = document.getElementById( "myChart3" ).getContext( "2d" ) ;
                    myChart3 = new Chart( ctx3, config3 ) ;
                    */
                    get_data_energy();
                },
                data: JSON.stringify({
                    smartdbnumber: mySmartdbnumber,
                    duration: duration
                }),
            });
        }


        function check_id_token() {
            var myToken = localStorage.getItem("3datavis-id-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/id-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    if (data["success"] == true) {
                     console.log(data);
                        localStorage.setItem("3datavis-username", data["username"]);
                        localStorage.setItem("gatewayid", data["gatewayid"]);
                        localStorage.setItem("smartdbnumber", data["smartdbnumber"]);
                        let name = data["name"];
                        let initials = "";
                        initials = get_initials(name);
                        $(".avatar-circle-xs").css('background-color', stringToHslColor(data["username"], 50, 60));
                        $('#set-initials').attr('data-letters', initials);
                        $("#set-initials").text(data["name"]);
                        //$("#set-fullname").text(data["firstname"] + " " + data["lastname"]);
                        $("#set-username").text(data["username"]);


                        get_tariff();

                        //get_devices_consumption();
                        //get_data();

                        $("#loader").addClass("fadeOut");
                    } else {
                        check_refresh_token();
                    }
                },
                data: JSON.stringify({
                    token: myToken
                }),
            });
        }

        function get_tariff() {

         $.ajax({
            url: "https://api2.thesmarthome.sg/tariff/user",
            type: "post",
            dataType: "json",
            headers: {
              "Authorization": localStorage.getItem("3datavis-id-token")
            },
            contentType: "application/json",
            success: function(data) {
               if (data["data"]["pp_rate"]) {
                 localStorage.setItem("tariff", data["data"]["pp_rate"]);
               } else {
                  localStorage.setItem("tariff", 0);
               }  
               get_devices_id();
             },
             data: JSON.stringify({
                 smartdbnumber: localStorage.getItem("smartdbnumber"),
                 username: localStorage.getItem("3datavis-username")
             }),
         });

     }



        function check_refresh_token() {
            var myToken = localStorage.getItem("3datavis-refresh-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/refresh-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log("ID");
                    //console.log(data);
                    if (data["success"] == true) {
                        localStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        check_id_token();
                    } else {
                        alert('Your session has expired! Please login again and refresh this page.');
                        window.location.href = '/signin';
                    }
                },
                data: JSON.stringify({
                    username: localStorage.getItem("3datavis-username"),
                    refresh_token: myToken
                }),
            });
        }

        window.addEventListener('load', () => {
            $("#loader").removeClass("fadeOut");
            check_id_token();
        });

        window.addEventListener('afterprint', () => {
            myChart.resize();
          });

          $(window).load(function(){ $('#myChart_masonry').masonry(); });
          //$(window).load(function(){ $('#myChart2_masonry').masonry(); });
    }
}())