import * as $ from 'jquery';



export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const AdminBox = document.getElementById('admin-box');

    

    if (AdminBox) {

        //check_id_token();

        // get notifications

        

        $("#dashboard-logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("3datavis-smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        $("#logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("3datavis-smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        function stringToHslColor(str, s, l) {
            //a string (str): we will use it to get the hue parameter of the returned color;
            //the saturation (s) of returned color: a number between 0 and 100;
            //the lightness (l) of returned color: a number between 0 and 100.
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
        }

        function get_initials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

                        
            let initials = [...name.matchAll(rgx)] || [];


            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        }

        function get_user_list() {
            $.ajax({
                url: "https://89381lkug1.execute-api.ap-southeast-1.amazonaws.com/v2/admin/users",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log(data["response"]["result"]);
                    //console.log(data["response"].appliances);
                    //console.log(JSON.parse(data["response"]["result"]));

                    console.log(data);

                    if (data["error"]) {
                        alert(data["message"]);
                        return;
                    }

                    var template;
                    $("#appliance_list").empty();
                    for (var i=0; i < data["data"].length; i++) {
                        console.log(data["data"][i]);
                        template = "<tr>" +
                            "<td>" + (i+1) + "</td>" +
                            "<td>" + data["data"][i]["name"] + "</td>" +
                            "<td>" + data["data"][i]["username"] + "</td>" +
                            "<td>" + data["data"][i]["email"] + "</td>" +
                            "<td>" + data["data"][i]["email_verified"] + "</td>" +
                            "<td>" + "<div class='peer'><button onclick='remove_user(" + "\"" + data["data"][i]["username"] + "\"" + ")' class='btn cur-p btn-outline-danger'>Remove</button></div>" + "</td>" +
                        "</tr>"
                        $("#appliance_list").append(template);
                    }
                },
                data: JSON.stringify({
                    token: localStorage.getItem("3datavis-id-token")
                })
            });
        }

        


        function check_id_token() {
            console.log("inside check id");
            var myToken = localStorage.getItem("3datavis-id-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/id-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    if (data["success"] == true) {
                        //console.log(data);
                        localStorage.setItem("3datavis-username", data["username"]);

                        localStorage.setItem("gatewayid", data["gatewayid"]);
                        let name = data["name"];
                        let initials = "";

                        initials = get_initials(name);
                        //console.log(data["name"]);
                        //console.log(initials);

                        $(".avatar-circle-xs").css('background-color', stringToHslColor(data["username"], 50, 60));
                        $('#set-initials').attr('data-letters', initials);
                        $("#set-initials").text(data["name"]);
                        //$("#set-fullname").text(data["firstname"] + " " + data["lastname"]);
                        $("#set-username").text(data["username"]);

                        get_user_list();

                        $("#loader").addClass("fadeOut");
                    } else {
                        check_refresh_token();
                    }
                },
                data: JSON.stringify({
                    token: myToken
                }),
            });
        }


        function check_refresh_token() {

            var myToken = localStorage.getItem("3datavis-refresh-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/refresh-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log("ID");
                    //console.log(data);
                    if (data["success"] == true) {
                        localStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        //check_id_token();
                    } else {
                        alert('Your session has expired! Please login again and refresh this page.');
                        window.location.href = '/signin';
                    }
                },
                data: JSON.stringify({
                    username: localStorage.getItem("3datavis-username"),
                    refresh_token: myToken
                }),
            });
        }

        window.addEventListener('load', () => {
            $("#loader").removeClass("fadeOut");
            check_id_token();
        });

    }
}())