import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const DashboardBox = document.getElementById('device-box');

    if (DashboardBox) {

        //check_id_token();

        // get notifications


        $("#dashboard-logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("3datavis-smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        $("#logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("3datavis-smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        function stringToHslColor(str, s, l) {
            //a string (str): we will use it to get the hue parameter of the returned color;
            //the saturation (s) of returned color: a number between 0 and 100;
            //the lightness (l) of returned color: a number between 0 and 100.
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
        }

        function get_initials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

                        
            let initials = [...name.matchAll(rgx)] || [];


            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        }

        function get_appliance_list(value) {
            //curl -X GET "https://provisioning.thesmarthome.sg/api/v1/appliances/00000000c6d59f1c-uat2test38no1" -H "accept: */*"
            $.ajax({
                url: "https://provisioning.thesmarthome.sg/api/v1/appliances/" + localStorage.getItem("gatewayid"),
                type: "get",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log(data["response"]["result"]);
                    //console.log(data["response"].appliances);
                    //console.log(JSON.parse(data["response"]["result"]));

                    var a = JSON.parse(data["response"]["result"]);

                    var b = a[0]["appliances"];
                    //console.log();
                    var template;
                    for (var i=0; i < b.length; i++) {
                        //console.log(b[i]["id"]);
                        template = "<tr>" +
                            "<td>" + b[i]["id"] + "</td>" +
                            "<td>" + b[i]["friendlyName"] + "</td>" +
                            "<td>" + b[i]["level"] + "</td>" +
                            "<td>" + b[i]["linkedBplId"] + "</td>" +
                            "<td>" + b[i]["postalCode"] + "</td>" +
                            "<td>" + b[i]["unitNo"] + "</td>" +
                        "</tr>"
                        $("#appliance_list").append(template);
                    }

                }
            });
        }


        function check_id_token() {
            var myToken = localStorage.getItem("3datavis-id-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/id-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    if (data["success"] == true) {
                        //console.log(data);
                        localStorage.setItem("3datavis-username", data["username"]);

                        localStorage.setItem("gatewayid", data["gatewayid"]);
                        let name = data["name"];
                        let initials = "";

                        initials = get_initials(name);
                        //console.log(data["name"]);
                        //console.log(initials);

                        $(".avatar-circle-xs").css('background-color', stringToHslColor(data["username"], 50, 60));
                        $('#set-initials').attr('data-letters', initials);
                        $("#set-initials").text(data["name"]);
                        //$("#set-fullname").text(data["firstname"] + " " + data["lastname"]);
                        $("#set-username").text(data["username"]);

                        get_appliance_list(data["gatewayid"]);

                        $("#loader").addClass("fadeOut");
                    } else {
                        check_refresh_token();
                    }
                },
                data: JSON.stringify({
                    token: myToken
                }),
            });
        }


        function check_refresh_token() {

            var myToken = localStorage.getItem("3datavis-refresh-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/refresh-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log("ID");
                    //console.log(data);
                    if (data["success"] == true) {
                        localStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        //check_id_token();
                    } else {
                        alert('Your session has expired! Please login again and refresh this page.');
                        window.location.href = '/signin';
                    }
                },
                data: JSON.stringify({
                    username: localStorage.getItem("3datavis-username"),
                    refresh_token: myToken
                }),
            });
        }

        window.addEventListener('load', () => {
            $("#loader").removeClass("fadeOut");
            check_id_token();
        });

    }
}())