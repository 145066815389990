import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const DashboardBox = document.getElementById('dashboard-box');

    if (DashboardBox) {

        console.log("Inside Dashboard");
        //check_id_token();

        // get notifications


        $("#dashboard-logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        $("#logout").click(function(event) {
            event.preventDefault();
            localStorage.setItem("3datavis-id-token", "");
            localStorage.setItem("3datavis-refresh-token", "");
            localStorage.setItem("smartdbnumber", "");
            localStorage.setItem("3datavis-username", "");
            window.location.href = '/signin';
        });

        function stringToHslColor(str, s, l) {
            //a string (str): we will use it to get the hue parameter of the returned color;
            //the saturation (s) of returned color: a number between 0 and 100;
            //the lightness (l) of returned color: a number between 0 and 100.
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
        }

        function get_initials(name) {
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

                        
            let initials = [...name.matchAll(rgx)] || [];


            initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase();

            return initials;
        }

        function setCurrentMonth() {
            const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

            const d = new Date();
            let name = month[d.getMonth()];
            $("#current_month").text(name + " " + new Date().getFullYear());
        }

        function setGeneralInformation(data) {
            $("#dashboard-unit").text(data["unit"]);
            $("#dashboard-level").text(data["level"]);
            $("#dashboard-postcode").text(data["postcode"]);
            $("#dashboard-smartdbnumber").text(data["smartdbnumber"]);
        }

        function getPowerConsumptionAndCurrentTariff() {

            $.ajax({
                url: "https://api2.thesmarthome.sg/energy/ct-value-desc",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "Authorization": localStorage.getItem("3datavis-id-token")
                },
                success: function(data) {
                    console.log(data);
                    // Block one power consumption list

                    //i.	Data1 -> energy/ct-value-desc
                    //ii.	Data2 -> energy/current-month
                    //iii.	Data3 -> tariff/user

                    $("#data-preview").empty();
                    for (let i = 0; i < data["data"].length; i++) {
                        var d = new Date(data["data"][i]["timestamp"] * 1000);
                        var template = '<tr><td class="fw-600">' + d.getHours() + ":" + d.getMinutes() + ", " + d.toDateString() + 
                            '</td><td>' +  data["data"][i]["device_id"] + '</td><td>' + 
                            data["data"][i]["active_power"] + '</td><td><span class="text-success">' + data["data"][i]["energy"] + 
                            '</span></td></tr>';
                        //console.log(data["data1"][i]);
                        $("#data-preview").append(template);
                    }

                    getPowerConsumption();
                    /*
                    // Block 2
                    console.log(data["data2"][0][0]);
                    $("#total-power-consumption").text( (data["data2"][0][0]).toFixed(2) );

                    // Block 3
                    console.log(data["data2"][0][0] * data["data3"][0][4]);
                    $("#total-energy-charge").text( (data["data2"][0][0] * data["data3"][0][4]).toFixed(2) );
                    */
                },
                data: JSON.stringify({
                    smartdbnumber: localStorage.getItem("smartdbnumber"),
                    username: localStorage.getItem("3datavis-username"),
                    amount: "10"
                }),
            });
        }

        var total_power_consumption = 0;

        function getPowerConsumption() {

            $.ajax({
                url: "https://api2.thesmarthome.sg/energy/current-month",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "Authorization": localStorage.getItem("3datavis-id-token")
                },
                success: function(data) {
                    console.log(data);

                    if (data["sum_energy"]) {
                        $("#total-power-consumption").text( (data["sum_energy"]).toFixed(2) );
                        total_power_consumption = data["sum_energy"];
                    }

                    getTariffInfo();
                    /*
                    // Block 2
                    console.log(data["data2"][0][0]);
                    $("#total-power-consumption").text( (data["data2"][0][0]).toFixed(2) );

                    // Block 3
                    console.log(data["data2"][0][0] * data["data3"][0][4]);
                    $("#total-energy-charge").text( (data["data2"][0][0] * data["data3"][0][4]).toFixed(2) );
                    */
                },
                data: JSON.stringify({
                    smartdbnumber: localStorage.getItem("smartdbnumber"),
                    username: localStorage.getItem("3datavis-username")
                }),
            });
        }

        function getTariffInfo() {

            $.ajax({
                url: "https://api2.thesmarthome.sg/tariff/user",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                headers: {
                    "Authorization": localStorage.getItem("3datavis-id-token")
                },
                success: function(data) {
                    console.log(data);

                    if (data["data"]["pp_rate"]) {
                        $("#total-energy-charge").text( "$" + (total_power_consumption * data["data"]["pp_rate"]).toFixed(2));
                    }
                    /*
                    // Block 2
                    console.log(data["data2"][0][0]);
                    $("#total-power-consumption").text( (data["data2"][0][0]).toFixed(2) );

                    // Block 3
                    
                    */
                },
                data: JSON.stringify({
                    smartdbnumber: localStorage.getItem("smartdbnumber"),
                    username: localStorage.getItem("3datavis-username")
                }),
            });
        }


        function setName(data) {
            let name = data["name"];
            let initials = "";

            console.log(data["name"]);
            console.log(initials);

            initials = get_initials(name);

            $(".avatar-circle-xs").css('background-color', stringToHslColor(data["username"], 50, 60));
            $('#set-initials').attr('data-letters', initials);
            $("#set-initials").text(data["name"]);
            //$("#set-fullname").text(data["firstname"] + " " + data["lastname"]);
            $("#set-username").text(data["username"]);
        };

        function check_id_token() {
            var myToken = localStorage.getItem("3datavis-id-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/id-token",
                type: "post",
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    if (data["success"] == true) {
                        console.log(data);
                        localStorage.setItem("3datavis-username", data["username"]);
                        localStorage.setItem("smartdbnumber", data["smartdbnumber"]);
                        setName(data);
                        setGeneralInformation(data);
                        setCurrentMonth();
                        getPowerConsumptionAndCurrentTariff();
                        //$("#loader").addClass("fadeOut");
                    } else {
                        check_refresh_token();
                    }
                },
                data: JSON.stringify({
                    token: myToken
                }),
            });
        }


        function check_refresh_token() {

            var myToken = localStorage.getItem("3datavis-refresh-token");

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/refresh-token",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    console.log("ID");
                    console.log(data);
                    if (data["success"] == true) {
                        localStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        check_id_token();
                        //setName(data);
                    } else {
                        alert('Your session has expired! Please login again and refresh this page.');
                        window.location.href = '/signin';
                    }
                },
                data: JSON.stringify({
                    username: localStorage.getItem("3datavis-username"),
                    refresh_token: myToken
                }),
            });
        }

        window.addEventListener('load', () => {
            $("#loader").removeClass("fadeOut");
            check_id_token();
        });

        $(window).load(function(){ $('#data-preview-masonry').masonry(); });

    }
}())