import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Signin
    // ------------------------------------------------------

    const SigninBox = document.getElementById('signin-box');

    if (SigninBox) {

        console.log("Inside signin box");
        $("#login").click(function() {
            event.preventDefault();
            login();
        });

        $("#forgot-password").click(function() {
            event.preventDefault();
            request_new_password();
        });


        // Get the input field
        var input_password = document.getElementById("password");
        input_password.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });

        var input_username = document.getElementById("username");
        input_username.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });

        var username, pass;

        function login() {

            $("#loader").removeClass("fadeOut");
            
            username = $("#username").val();
            pass = $("#password").val();
            $("#error-message").text("");

            if (username == "" || pass == "") {
                $("#error-message").text("Username/password is missing");
                $("#error-message").css("color", "red");
                return;
            }

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/signin",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    console.log(data);
                    
                    if (data["success"]) {
                        localStorage.setItem("3datavis-refresh-token", data["data"]["refresh_token"]);
                        localStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        window.location.href = '/';
                    } else {
                        console.log("FAILED");
                        $("#loader").addClass("fadeOut");
                        if (data["message"] == "NEW_PASSWORD_REQUIRED") {
                            localStorage.setItem("3datavis-session-token", data["data"]);
                            window.location.href = '/reset-password';
                        } else {
                            $("#error-message").text(data["message"]);
                            $("#error-message").css("color", "red");
                        }
                    }
                },
                data: JSON.stringify({
                    username: username,
                    password: pass
                }),
            });
        }

        function request_new_password() {

            //$("#loader").removeClass("fadeOut");
            
            username = $("#username").val();
            $("#error-message").text("");
            
            if (username == "") {
                $("#error-message").text("Username is missing");
                $("#error-message").css("color", "red");
                return;
            }

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/forgot-password",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    console.log(data);
                    
                    if (data["success"]) {
                        window.location.href = '/forgot-password';
                    } else {
                        console.log("FAILED");
                        $("#loader").addClass("fadeOut");
                        if (data["message"] == "NEW_PASSWORD_REQUIRED") {
                            localStorage.setItem("3datavis-session-token", data["data"]);
                            window.location.href = '/reset-password';
                        } else {
                            $("#error-message").text(data["message"]);
                            $("#error-message").css("color", "red");
                        }
                    }
                },
                data: JSON.stringify({
                    username: username
                }),
            });
        }
    }
}())