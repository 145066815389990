// import "@popperjs/core";
import bootstrap from 'bootstrap';

import '../styles/index.scss';
//import './fullcalendar';
import './registerController';
import './flatVerification';
import './signup';
import './admin';
import './signin';
import './consumption';
import './device';
import './settings';
import './confirmEmail';
import './forgetPassword';
import './forgotPassword';
import './dashboard';
import './masonry';
import './charts';
//import './popover';
//import './scrollbar';
//import './search';
//import './sidebar';
//import './skycons';
//import './vectorMaps';
//import './chat';
//import './datatable';
//import './datepicker';
//import './email';
//import './googleMaps';
//import './utils';

