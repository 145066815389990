import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Signin
    // ------------------------------------------------------

    const FlatVerificationBox = document.getElementById('flat-verification-box');

    if (FlatVerificationBox) {

        console.log("Inside Verification box");
        $("#submit").click(function() {
            event.preventDefault();
            location.href = '/signup';
        });

    }
}())