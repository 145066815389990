import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Signin
    // ------------------------------------------------------

    const SignupBox = document.getElementById('signup-box');

    if (SignupBox) {


        if ( localStorage["secret-session"] != "f*-wY9$t{/oHVoeMH|HA") {
            location.href = '/signin';
        }
        $("#error-message").css("color", "blue");

        //console.log("Inside signup box");
        $("#register").click(function() {
            event.preventDefault();
            login();
        });

        // Get the input field
        var input_name = document.getElementById("name");
        input_name.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });

        var input_password1 = document.getElementById("password1");
        input_password1.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });
        var input_password2 = document.getElementById("password2");
        input_password2.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });

        var input_username = document.getElementById("username");
        input_username.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });

        var input_email = document.getElementById("email");
        input_email.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                login();
            }
        });

        var username, pass1, pass2, email, name;

        function login() {

            //$("#loader").removeClass("fadeOut");
            name = $("#name").val();
            username = $("#username").val();
            pass1 = $("#password1").val();
            pass2 = $("#password2").val();
            email = $("#email").val();
            $("#error-message").text("");

            if (username == "" || email == "" || pass1 == "" || pass2 == "" || name == "") {
                $("#error-message").text("Please fill in the fields");
                $("#error-message").css("color", "red");
                return;
            }

            if (pass1 != pass2) {
                $("#error-message").text("Passwords do not match");
                $("#error-message").css("color", "red");
                return;
            }

            //console.log(username, pass1, email, name);

            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/signup",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    //console.log(data);
                    if (data["success"]) {
                        //localStorage.setItem("3datavis-refresh-token", data["data"]["refresh_token"]);
                        //ocalStorage.setItem("3datavis-id-token", data["data"]["id_token"]);
                        //alert('Please verify your email...');
                        localStorage.clear();
                        window.location.href = '/confirm-email-address';
                    } else {
                        //console.log("FAILED");
                        $("#loader").addClass("fadeOut");
                        if (data["message"] == "NEW_PASSWORD_REQUIRED") {
                            localStorage.setItem("3datavis-session-token", data["data"]);
                            window.location.href = '/reset-password';
                        } else {
                            $("#error-message").text(data["message"]);
                            $("#error-message").css("color", "red");
                        }
                    }
                },
                data: JSON.stringify({
                    username: username,
                    password: pass1,
                    email: email,
                    name: name,
                    unit: localStorage["unit"],
                    level: localStorage["level"],
                    phone: localStorage["phone"],
                    postcode: localStorage["postcode"],
                    gatewayid: localStorage["gatewayid"],
                    smartdbnumber: localStorage["smartdbnumber"]
                }),
            });
        }
    }
}())