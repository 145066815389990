import * as $ from 'jquery';


export default (function() {
    // ------------------------------------------------------
    // @Forgot Password
    // ------------------------------------------------------

    const ResetPasswordBox = document.getElementById('forget-password-box');

    if (ResetPasswordBox) {

        //console.log("Inside Forget PASSWORD");
        //console.log("Trigger code");

        //request_change_password();
        //var pass, pass1, pass2, username, email, fullname, code;

        $("#forgotBtn").click(function() {
            event.preventDefault();
            set_password();
        });

        var input_username = document.getElementById("username");
        input_username.addEventListener("keyup", function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                set_password();
            }
        });


        function set_password() {
            console.log("Clicked set password!");
            
            //var myToken = localStorage.getItem("3datavis-session-token");

            //console.log(myToken);
            
            var username;

            username = $("#username").val();

            if ( username == "" ) {
                $("#error-message").text("Please fill in the username");
                $("#error-message").css("color", "red");
                return;
            }

            $("#loader").removeClass("fadeOut");
            
            $.ajax({
                url: "https://api2.thesmarthome.sg/auth/forgot-password",
                type: "post",
                dataType: "json",
                contentType: "application/json",
                success: function(data) {
                    console.log(data);
                    
                    if (data["success"]) {
                        window.location.href = '/forgot-password';
                    } else {
                        console.log("FAILED");
                        $("#loader").addClass("fadeOut");
                        if (data["message"] == "NEW_PASSWORD_REQUIRED") {
                            localStorage.setItem("3datavis-session-token", data["data"]);
                            window.location.href = '/reset-password';
                        } else {
                            $("#error-message").text(data["message"]);
                            $("#error-message").css("color", "red");
                        }
                    }
                },
                data: JSON.stringify({
                    username: username
                }),
            });
        }
    }
}())